
import {
    reactive
} from "vue";
import validate from 'ele-admin-pro/packages/validate';
export default {
    props: {
        formState: {
            type: Object,
            default: () => {},
        },
        visible: Boolean
    },
    data() {
        return {
            loading: false,
            // 表单规则
            rules: {
                user_account_code: [{
                    required: true,
                    message: this.$t("user.tips.user_account_code_input"),
                    trigger: "blur",
                }],
                bank_name: [{
                    required: true,
                    message: this.$t("user.tips.user_account_code_input"),
                    trigger: "blur",
                }],
                account_name: [{
                    required: true,
                    message: this.$t("user_withdraw.tips.bank_branch"),
                    trigger: "blur",
                }],
                account_number: [{
                    required: true,
                    message: this.$t("user_withdraw.tips.bank_bccount"),
                    trigger: "blur",
                }],
                value: [{
                    required: true,
                    message: this.$t("user.tips.charge_value_input"),
                    trigger: "blur",
                }, {
                    validator: (rule, value) => {
                        if (!value) {
                            return Promise.reject(this.$t("user.tips.charge_value_input"));
                        } else if (!validate.isNumber(value)) {
                            return Promise.reject(this.$t("user_withdraw.tips.tips_isNumber"));
                        } else {
                            return Promise.resolve();
                        }
                    },
                }],
            }
        };
    },
    computed: {
        // 表单信息重新计算表
        computedFormState: {
            get() {
                return reactive(Object.assign({}, this.formState));
            },
            set(val) {
                this.$emit("update:formState", val);
            },
        },
    },
    methods: {
        // 提交表单
        handleSubmit() {
            this.$refs.formRef.validate().then(() => {
                this.loading = true;

                this.$http.post(`/user/withdraw/create`, this.computedFormState)
                    .then((res) => {
                        if (res.status == 200 && res.data.code == 0) {
                            this.$message.success("share.success");
                            this.$emit("update:visible", false);
                            this.$emit("reload");
                        } else {
                            this.$message.error(res.data.msg);
                        }
                    }).catch(e => {
                        this.$message.error(e.message);
                    }).finally(() => {
                        this.loading = false;
                    });
            })
        }
    }
};
