
import {
    defineComponent,
    createVNode
} from "vue";
import url from "@/assets/error/error";
import {
    PlusOutlined,
    ExclamationCircleOutlined,
    BankOutlined,
    CreditCardOutlined
} from "@ant-design/icons-vue";

import editor from "./component/editor.vue";

export default defineComponent({
    name: "AccountWithdraw",
    components: {
        PlusOutlined,
        BankOutlined,
        CreditCardOutlined,
        editor
    },
    data() {
        return {
            url: "/user/withdraw/list",
            where: {},
            current: {},
            selection: [],
            visible: false,
            // 表单内容 
            voidForm: {},
            // 预览图片
            previewVisible: false,
            // 图片资源
            previewImage: "",

            columns: [{
                    title: this.$t("user_withdraw.fields.value"),
                    dataIndex: "charge",
                    width: 120,
                    customRender: ({
                        record
                    }) => `${record.charge} ${record.currency}`
                },{
                    title: this.$t("user_withdraw.fields.account"),
                    width: 280,
                    slots: {
                        customRender: "account"
                    }
                },
                {
                    key: "remark",
                    title: this.$t("share.remark"),
                    dataIndex: "remark",
                    slots: {
                        customRender: "remark"
                    }
                },
                {
                    title: this.$t("user_withdraw.fields.created"),
                    key: "created",
                    align: "center",
                    dataIndex: "created",
                    width: 150
                },
                {
                    title: this.$t("user_withdraw.fields.audit_time"),
                    key: "audit_time",
                    align: "center",
                    dataIndex: "audit_time",
                    width: 150,
                    customRender: ({
                        text
                    }) => text || '-'
                },
                {
                    title: this.$t("user_withdraw.attachment"),
                    align: "center",
                    width: 100,
                    slots: {
                        customRender: "attachment",
                    }
                },
                {
                    title: this.$t("share.status"),
                    key: "status",
                    dataIndex: "status",
                    width: 100,
                    slots: {
                        customRender: "status_active"
                    }
                },
                {
                    key: "action",
                    title: this.$t("share.operation"),
                    dataIndex: "action",
                    align: "center",
                    width: 120,
                    slots: {
                        customRender: "action",
                    }
                }
            ]
        };
    },
    computed: {
        // 重构文件列表
        getFileList: {
            get() {
                return this.fileList;
            },
            set(val) {
                this.fileList = val.slice(val.length - 1);
            },
        }
    },
    methods: {
        // 处理错误图片
        handleError() {
            this.previewImage = url.base64URL;
        },
        // 展示附件
        showFile(row) {
            this.previewVisible = true;
            this.previewImage = row.attachment;
        },
        // 删除功能
        handleDelete(row) {
            // 禁用功能
            if ([2, 3].includes(row.status)) {
                return
            }
            if (row) {
                this.selection = [row];
            }
            if (!this.selection.length) {
                return this.$message.error('share.select_one_more_then');
            }

            this.$confirm({
                title: this.$t('share.tips'),
                content: this.$t('share.delete_confirm'),
                icon: createVNode(ExclamationCircleOutlined),
                maskClosable: true,
                onOk: () => {
                    const hide = this.$message.loading('share.requesting', 0);

                    this.$http.post(`/user/withdraw/delete`,{
                        ids:this.selection.map(d => d.id)
                    }).then(res => {
                        hide();
                        if (res.data.code === 0) {
                            this.$message.success(res.data.msg);
                            this.reload();
                        } else {
                            this.$message.error(res.data.msg);
                        }
                    }).catch(e => {
                        hide();
                        this.$message.error(e.message);
                    });
                }
            });
        },
        // 提现申请
        withdrawCreate(row) {
            this.visible = true;
            this.current = row;
        },
        reload() {
            this.selection = [];
            this.$refs.table.reload({
                page: 1,
            });
        },
        onClose() {
            this.visible = false;
        }
    }
});
